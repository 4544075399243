<template>
  <section class="ajustes">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="cursor" />
      </div>
      <p>Tela da Aula</p>
    </div>

    <!-- Fonte -->
    <label>Fonte</label>
    <FontPicker :valor="tela.fonte" :onAtualizarFonte="(novaFonte) => atualizarFonte('fonte', novaFonte)" />

    <!-- Cores -->
    <label>Cor Fundo</label>
    <ColorPicker :valor="tela.corFundo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundo', novaCor)" />

    <label>Cor Título</label>
    <ColorPicker :valor="tela.corTitulo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTitulo', novaCor)" />

    <label>Cor Descrição</label>
    <ColorPicker :valor="tela.corDescricao" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corDescricao', novaCor)" />

    <label>Cor Duração</label>
    <ColorPicker :valor="tela.corDuracao" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corDuracao', novaCor)" />

    <label>Cor Links</label>
    <ColorPicker :valor="tela.corLinks" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corLinks', novaCor)" />

    <label>Cor Arquivos</label>
    <ColorPicker :valor="tela.corArquivos" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corArquivos', novaCor)" />

    <label>Cor de Posicao de Texto</label>
    <ColorPicker :valor="tela.corPosicaoTexto" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corPosicaoTexto', novaCor)" />

    <label>Cor de Posicao de Fundo</label>
    <ColorPicker :valor="tela.corPosicaoFundo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corPosicaoFundo', novaCor)" />

    <label>Cor Fundo Campo Comentário</label>
    <ColorPicker :valor="tela.corFundoCampoComentario" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoCampoComentario', novaCor)" />

    <label>Cor Texto Campo Comentário</label>
    <ColorPicker :valor="tela.corTextoCampoComentario" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoCampoComentario', novaCor)" />

    <label>Cor Textos Comentários</label>
    <ColorPicker :valor="tela.corTextosComentarios" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextosComentarios', novaCor)" />

    <label>Cor Texto Botão Comentar</label>
    <ColorPicker :valor="tela.corTextoBotaoComentar" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoBotaoComentar', novaCor)" />

    <label>Cor Fundo Botão Comentar</label>
    <ColorPicker :valor="tela.corFundoBotaoComentar" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoBotaoComentar', novaCor)" />

    <label>Cor Texto Botão Responder</label>
    <ColorPicker :valor="tela.corTextoBotaoResponder" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoBotaoResponder', novaCor)" />

    <label>Cor Fundo Botão Responder</label>
    <ColorPicker :valor="tela.corFundoBotaoResponder" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoBotaoResponder', novaCor)" />

    <label>Cor Texto Data Comentário</label>
    <ColorPicker :valor="tela.corTextoDataComentario" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoDataComentario', novaCor)" />

    <label>Cor Fundo Aula</label>
    <ColorPicker :valor="tela.corFundoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoAula', novaCor)" />

    <label>Cor Texto Aula</label>
    <ColorPicker :valor="tela.corTextoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoAula', novaCor)" />

    <label>Cor SVG Botão Aula</label>
    <ColorPicker :valor="tela.corSvgBotaoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corSvgBotaoAula', novaCor)" />

    <label>Cor Fundo Botão Aula</label>
    <ColorPicker :valor="tela.corFundoBotaoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoBotaoAula', novaCor)" />

    <label>Cor Texto Posicao Aula</label>
    <ColorPicker :valor="tela.corTextoPosicaoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoPosicaoAula', novaCor)" />

    <label>Cor Fundo Posicao Aula</label>
    <ColorPicker :valor="tela.corFundoPosicaoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoPosicaoAula', novaCor)" />

    <label>Cor Texto Duração Aula</label>
    <ColorPicker :valor="tela.corTextoDuracaoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoDuracaoAula', novaCor)" />

    <label>Cor Fundo Duração Aula</label>
    <ColorPicker :valor="tela.corFundoDuracaoAula" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoDuracaoAula', novaCor)" />

    <div class="salvar">
      <button @click="salvar()">
        <Svgs nome="subir" />
        <p>Salvar Alterações</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStorePersonalizacao, useStoreAreas } from '@stores'
import FontPicker from '@components/global/elementos/FontPicker.vue'
import ColorPicker from '@components/global/elementos/ColorPicker.vue'
import Svgs from '@svgs'

const storePersonalizacao = useStorePersonalizacao()
const storeAreas = useStoreAreas()

const tela = computed(() => storePersonalizacao.personalizacao?.telaAula || {})

function atualizarCorSelecionada(propriedade, novaCor) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = `#${novaCor}`
  }
}

function atualizarFonte(propriedade, novaFonte) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = novaFonte
  }
}

async function salvar() {
  const idArea = storeAreas.area._id

  const payload = {
    personalizacao: {
      telaAula: tela.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(idArea, payload)
  if (editadoOk !== true) return
}
</script>

<style scoped>
section.ajustes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 100px 20px;
  width: 100%;
  overflow-y: scroll;
}
label {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
}
label svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}
textarea {
  background-color: var(--cor-cinza-3);
}
textarea:focus {
  background-color: var(--cor-cinza-4);
}
.layouts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 135px;
  overflow-x: scroll;
}
.layouts button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  min-width: 160px;
  margin: 0 15px 0 0;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  padding: 0 0 15px 0;
  transition: all 0.3s;
}
.layouts button.ativo {
  border: 1px solid var(--cor-laranja);
}
.layouts button .capa {
  width: 100%;
  height: 90px;
  background-size: cover;
  background-position: center;
}
.layouts button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 0 0;
}
.titulo {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0 0 0;
}
.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--cor-cinza-3);
}
.titulo .icone svg {
  width: 13px;
  min-width: 13px;
  max-height: 13px;
  fill: var(--cor-branco);
}
.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
}
.salvar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 250px;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  z-index: 7;
}
.salvar button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--cor-laranja);
  padding: 15px 12px;
  width: 100%;
  transition: all 0.3s;
}
.salvar button:hover {
  background-color: var(--cor-laranja-escuro);
}
.salvar button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}
.salvar button svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}
</style>
