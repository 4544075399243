<template>
  <Modal nome="editarFavicon">
    <div class="conteudo">
      <label class="um">
        Ícone da .area (em .ico)
        <span>*</span>
      </label>
      <div class="inputImagem" :class="{ carregar: state.imagemIcoLoading }" @click="abrirInputFileIco">
        <span></span>
        <div class="previa" :style="`background-image: url('${state.imagemIco}')`">
          <Svgs v-if="!state.imagemIco" nome="arquivo" />
        </div>
        <div class="texto">
          <h3>{{ state.imagemIcoNome || 'Subir Imagem' }}</h3>
          <p>{{ state.imagemIcoTamanho || 'Clique aqui ou arraste a imagem da sua foto' }}</p>
        </div>
        <input type="file" accept=".ico" hidden @change="selecionarImagemIco" ref="inputFileIco" />
      </div>

      <label>
        Ícone da .area (em .png)
        <span>*</span>
      </label>
      <div class="inputImagem" :class="{ carregar: state.imagemPngLoading }" @click="abrirInputFilePng">
        <span></span>
        <div class="previa" :style="`background-image: url('${state.imagemPng}')`">
          <Svgs v-if="!state.imagemPng" nome="arquivo" />
        </div>
        <div class="texto">
          <h3>{{ state.imagemPngNome || 'Subir Imagem' }}</h3>
          <p>{{ state.imagemPngTamanho || 'Clique aqui ou arraste a imagem da sua foto' }}</p>
        </div>
        <input type="file" accept=".png" hidden @change="selecionarImagemPng" ref="inputFilePng" />
      </div>
    </div>

    <BotaoSalvar nome="Salvar Favicon" @click="salvarDados()" />
  </Modal>
</template>

<script setup>
import { reactive, ref, onMounted, inject } from 'vue'
import { useStoreModal, useStoreAreas } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import ApiUpload from '@/api/upload/api-upload.js'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const inputFileIco = ref(null)
const inputFilePng = ref(null)

const state = reactive({
  imagemIco: '',
  imagemIcoNome: '',
  imagemIcoTamanho: '',
  imagemIcoLoading: false,
  imagemPng: '',
  imagemPngNome: '',
  imagemPngTamanho: '',
  imagemPngLoading: false
})

function abrirInputFileIco() {
  inputFileIco.value.click()
}

function abrirInputFilePng() {
  inputFilePng.value.click()
}

function selecionarImagemIco(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.imagemIcoLoading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 300
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.imagemIcoLoading = false
      state.imagemIco = resp?.url
      state.imagemIcoNome = resp?.nome
      state.imagemIcoTamanho = resp?.peso
    })
    .catch(() => {
      state.imagemIcoLoading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir o ícone ICO' })
    })
}

function selecionarImagemPng(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.imagemPngLoading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 300
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.imagemPngLoading = false
      state.imagemPng = resp?.url
      state.imagemPngNome = resp?.nome
      state.imagemPngTamanho = resp?.peso
    })
    .catch(() => {
      state.imagemPngLoading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir o ícone PNG' })
    })
}

async function salvarDados() {
  const idArea = storeAreas?.area?._id

  const payload = {
    metatags: {
      favicon: state.imagemIco + state.imagemPng
    }
  }

  await storeAreas.alterarDados(idArea, payload)
  storeModal.fecharModal('editarFavicon')
}

function preencherDados() {
  const urlCompleta = storeAreas?.area?.metatags?.favicon || ''
  const [primeiraParte, segundaParte] = urlCompleta.split(/(?<=\.[a-z]+)(?=https:\/\/)/)

  state.imagemIco = primeiraParte
  state.imagemPng = segundaParte
}

function limparCampos() {
  state.imagemIco = ''
  state.imagemIcoNome = ''
  state.imagemIcoTamanho = ''
  state.imagemPng = ''
  state.imagemPngNome = ''
  state.imagemPngTamanho = ''
}

onMounted(() => {
  emitter.on('carregarCampos', preencherDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .campos {
    padding: 20px 20px 0 20px;
  }

  .campos .arquivo {
    flex-direction: column;
  }

  .campos .arquivo button {
    padding: 30px 20px;
    width: 100%;
  }

  .campos .arquivo button img {
    max-width: 20px;
  }

  .campos .arquivo .previa {
    width: 100%;
    min-height: 150px;
    margin: 10px 0 0 0;
  }
}
</style>
