<template>
  <section class="edicao" ref="sectionEdicao" :class="{ aparecerMobile: state.mostrarEdicoesMobile }" @mouseenter="adicionarClasseBloqueado" @mouseleave="removerClasseBloqueado">
    <!-- Topo -->
    <div class="topo">
      <button class="voltar" @click="voltarAoInicio(storeAreas.area?.hotlink)">
        <Svgs nome="seta" />
        <p>Voltar ao início</p>
      </button>
      <button class="status ativo">
        <h3 class="um">Online</h3>
      </button>
    </div>
    <!-- Nome -->
    <div class="nome">
      <h3>{{ storeAreas.area?.nome || 'Nome da Área' }}</h3>
      <a :href="pegarLink(storeAreas.area?.hotlink)" target="_blank">
        {{ storeAreas.area?.dominioPersonalizado || 'mber.live/' + storeAreas.area?.hotlink }}
      </a>
      <button class="menu" :class="{ ativo: state.mostrarEdicoesMobile }" @click="state.mostrarEdicoesMobile = !state.mostrarEdicoesMobile">
        <span class="um"></span>
        <span class="dois"></span>
      </button>
    </div>
    <!-- Edições -->
    <div class="edicoes" :class="{ aparecer: state.mostrarEdicoesMobile }">
      <div class="topo">
        <button class="voltar" @click="voltarAoInicio(storeAreas.area?.hotlink)">
          <Svgs nome="seta" />
          <p>Voltar ao início</p>
        </button>
        <button class="status ativo">
          <h3 class="um">Online</h3>
        </button>
      </div>
      <div class="opcoes">
        <button @click="abrirModalInformacoes(storeAreas.area?._id)">
          <div class="icone">
            <Svgs nome="editar" />
          </div>
          <p>Editar Informações</p>
        </button>
        <button @click="abrirModalFavicon()">
          <div class="icone">
            <Svgs nome="favicon" />
          </div>
          <p>Personalizar Favicon</p>
        </button>
        <button @click="abrirModalFacebook()">
          <div class="icone">
            <Svgs nome="meta" />
          </div>
          <p>Pixel do Facebook</p>
        </button>
        <button @click="abrirModalGoogleTag()">
          <div class="icone">
            <Svgs nome="google" />
          </div>
          <p>Google Tag Manager</p>
        </button>
        <button @click="abrirModalExcluir(storeAreas.area?._id)">
          <div class="icone">
            <Svgs nome="excluir" />
          </div>
          <p>Excluir essa área</p>
        </button>
      </div>
      <!-- Camadas -->
      <div class="camadas">
        <div class="titulo">
          <h3>Telas da área</h3>
        </div>

        <button v-for="(tela, index) in state.telas" :class="{ ativo: storePersonalizacao.telaSelecionada === tela.selecao }" @click="selecionarTela(tela.selecao)" :key="`tela-${index}`">
          <div class="icone">
            <Svgs :nome="tela.icone" />
          </div>
          <p>{{ tela.nome }}</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref, onMounted, watch, onUnmounted } from 'vue'
import { useStoreAreas, useStoreModal, useStorePersonalizacao } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const storePersonalizacao = useStorePersonalizacao()

const sectionEdicao = ref(null)
const acessoDesktop = ref(window.innerWidth >= 1024)

const state = reactive({
  mostrarEdicoesMobile: false,
  debounceOnline: null,
  dispositivo: 'desktop',
  mostrarItens: false,
  telas: [
    {
      nome: 'Menu da Área',
      selecao: 'menu',
      icone: 'menu'
    },
    {
      nome: 'Tela de Login',
      selecao: 'telaLogin',
      icone: 'envelope'
    },
    {
      nome: 'Tela de Alterar Senha',
      selecao: 'telaAlterarSenha',
      icone: 'cadeado'
    },
    {
      nome: 'Tela de Loading',
      selecao: 'telaLoading',
      icone: 'atualizacoes'
    },
    {
      nome: 'Tela dos Cursos',
      selecao: 'telaCursosArea',
      icone: 'template'
    },
    {
      nome: 'Tela do Curso',
      selecao: 'telaCurso',
      icone: 'curso'
    },
    {
      nome: 'Tela do Módulo',
      selecao: 'telaModulo',
      icone: 'modulo'
    },
    {
      nome: 'Tela da Aula',
      selecao: 'telaAula',
      icone: 'play'
    },
    {
      nome: 'Tela de Perfil',
      selecao: 'telaPerfil',
      icone: 'usuario'
    }
  ]
})

function selecionarTela(tela) {
  storePersonalizacao.telaSelecionada = tela
}

function pegarLink(area) {
  if (area?.dominioPersonalizado) {
    return `https://${area?.dominioPersonalizado}`
  } else {
    return `https://mber.live/${area?.hotlink}`
  }
}

function voltarAoInicio(hotlink) {
  window.location.assign(`/areas/${hotlink}`)
}

function abrirModalInformacoes(id) {
  storeModal.abrirModalEdicao('editarArea', 'editar', 'Editar área', id)
}

function abrirModalFavicon() {
  storeModal.abrirModal('editarFavicon', 'favicon', 'Editar favicon')
}

function abrirModalExcluir(id) {
  storeModal.abrirModalEdicao('excluirArea', 'excluir', 'Excluir área', id)
}

function abrirModalFacebook() {
  storeModal.abrirModal('facebookMeta', 'meta', 'Pixel do Facebook')
}

function abrirModalGoogleTag() {
  storeModal.abrirModal('googleTag', 'google', 'Google Tag Manager')
}

function adicionarClasseBloqueado() {
  document.body.classList.add('bloqueado')
}

function removerClasseBloqueado(event) {
  if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
    document.body.classList.remove('bloqueado')
  }
}

function verificarTela() {
  acessoDesktop.value = window.innerWidth >= 1024
}

watch(
  () => state.mostrarEdicoesMobile,
  (novoValor) => {
    if (novoValor) {
      document.body.classList.add('bloqueado')
    } else {
      document.body.classList.remove('bloqueado')
    }
  }
)

onMounted(() => {
  window.addEventListener('resize', verificarTela)
})

onUnmounted(() => {
  window.removeEventListener('resize', verificarTela)
  document.body.classList.remove('bloqueado')
})
</script>

<style scoped>
section.edicao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

.edicoes::-webkit-scrollbar-thumb {
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
}

.edicoes::-webkit-scrollbar-track {
  background-color: transparent;
}

.edicoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.edicoes .topo {
  display: none;
}

.nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
}

.nome h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
}

.nome a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-azul);
  line-height: 1.5;
  word-break: break-all;
  transition: all 0.3s;
}

.nome a:hover {
  color: var(--cor-azul);
}

.nome button.menu {
  display: none;
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  background-color: transparent;
}

.nome button.menu span {
  position: absolute;
  background-color: var(--cor-branco);
  border-radius: 10px;
  height: 3px;
  transition: all 0.5s;
}

.nome button.menu:hover span {
  background-color: var(--cor-cinza-5);
}

.nome button.menu span.um {
  top: 10px;
  right: 0;
  width: 25px;
}

.nome button.menu span.dois {
  bottom: 10px;
  right: 0;
  width: 35px;
}

.nome button.menu.ativo span {
  background-color: var(--cor-vermelho);
}

.nome button.menu.ativo span.um {
  transform: rotate(45deg);
  width: 35px;
  top: 16px;
}

.nome button.menu.ativo span.dois {
  transform: rotate(-45deg);
  bottom: 16px;
}

.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.topo button.voltar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}

.topo button.voltar:hover svg {
  fill: var(--cor-cinza-5);
}

.topo button.voltar:hover p {
  color: var(--cor-cinza-5);
}

.topo button.voltar svg {
  width: 11px;
  min-width: 11px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
  transform: rotate(-180deg);
  transition: all 0.3s;
}

.topo button.voltar p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.topo .status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 25px;
  border-radius: 50px;
  background-color: var(--cor-vermelho);
  position: relative;
  pointer-events: none;
}

.topo .status.ativo {
  background-color: var(--cor-azul);
}

.topo .status.ativo h3 {
  color: var(--cor-branco-fixo);
}

.topo .status h3 {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-cinza-5);
  animation: fadeIn 0.5s linear;
  transition: all 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(10px, 0);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.opcoes {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 100%;
}

.opcoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: transparent;
  padding: 8px 0;
}

.opcoes button.breve {
  pointer-events: none;
  opacity: 0.5;
}

.opcoes button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.opcoes button .icone svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
}

.opcoes button:hover p {
  color: var(--cor-cinza-5);
}

.opcoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.camadas {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 100px 20px;
  width: 100%;
  border-top: 2px solid var(--cor-cinza-3);
}

.camadas .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.camadas .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.camadas button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: transparent;
  margin: 0 0 20px 0;
}

.camadas button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.camadas button.ativo .icone {
  background-color: var(--cor-laranja);
}

.camadas button.ativo .icone svg {
  fill: var(--cor-branco-fixo);
}

.camadas button .icone svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

.camadas button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.camadas button:hover p {
  color: var(--cor-branco);
}

.camadas button.ativo p {
  color: var(--cor-branco);
}

.subir {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  padding: 20px;
}

.subir.mobile {
  display: none;
}

.subir.desktop {
  display: flex;
}

.subir button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--cor-azul);
  padding: 15px 20px;
  border-radius: 10px;
  transition: all 0.3s;
}

.subir button:hover {
  background-color: var(--cor-azul-escuro);
}

.subir button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.subir button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.edicao {
    min-height: auto;
    max-height: auto;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
  }

  section.edicao.aparecerMobile {
    border-radius: 10px;
  }

  .detalhe {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
  }

  .edicoes {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 0;
    height: 0dvh;
    transition: all 0.5s;
  }

  .edicoes .topo {
    display: flex;
    padding: 10px 20px 20px 20px;
  }

  .edicoes.aparecer {
    height: 42dvh;
  }

  .nome button.menu {
    display: flex;
  }

  .parte {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 15px 15px 15px;
    margin: 0 0 0 0;
    border-bottom: none;
    border-top: 2px solid var(--cor-cinza-3);
  }

  .topo {
    display: none;
  }

  .nome {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px 10px 20px;
  }

  .nome .info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .status {
    flex-direction: row-reverse;
    margin: 0;
  }

  .status h3 {
    margin: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
      transform: translate(-10px, 0);
    }

    100% {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }

  .opcoes {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    width: 100%;
  }

  .opcoes .botoes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    scrollbar-width: none;
    padding: 10px 20px 10px 20px;
  }

  .opcoes .botoes::-webkit-scrollbar {
    display: none;
  }

  .opcoes button svg {
    width: 12px;
    min-width: 12px;
  }

  .subir {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 20px;
  }

  .subir.mobile {
    display: flex;
  }

  .subir.desktop {
    display: none;
  }

  .subir button {
    padding: 15px 20px;
  }

  .subir button p {
    font-size: var(--f2);
  }

  .camadas {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    width: 100%;
    border-top: 2px solid var(--cor-cinza-3);
  }
}
</style>
