<template>
  <section class="login" :class="tela.layout">
    <div class="imagem" :style="conteudoEstilo"></div>
    <div class="conteudo" :style="quadroEstilo">
      <div class="titulo">
        <img :src="`${tela.imagemLogo || 'https://arquivos.levelmember.com/imagens/default/logo.svg'}`" />
        <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de login' }}</p>
      </div>
      <div class="campos">
        <label :style="labelEstilo">E-mail da conta</label>
        <div class="inputDefault">
          <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" :style="inputEstilo" />
        </div>
        <label :style="labelEstilo">Senha de acesso</label>
        <div class="inputDefault">
          <input type="password" spellCheck="false" placeholder="senha#123" autoComplete="current-password" :style="inputEstilo" />
        </div>
        <button class="principal" :style="botaoEstilo">
          {{ tela.textoBotaoEntrar || 'Entrar Agora' }}
          <span></span>
        </button>
        <div class="opcoes">
          <a target="_blank" v-if="tela.linkBotaoExtraUm" :href="tela.linkBotaoExtraUm" :style="botoesExtrasEstilo">{{ tela.textoBotaoExtraUm || 'Botão Extra 1' }}</a>
          <a target="_blank" v-if="tela.linkBotaoExtraDois" :href="tela.linkBotaoExtraDois" :style="botoesExtrasEstilo">{{ tela.textoBotaoExtraDois || 'Botão Extra 2' }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStorePersonalizacao } from '@stores'

const storePersonalizacao = useStorePersonalizacao()

const tela = computed(() => storePersonalizacao.personalizacao?.telaLogin || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#FFFFFF',
  backgroundImage: `url('${tela.value.imagemFundo || ''}')`
}))

const quadroEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro || '#E1E3EB'
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#252939',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const labelEstilo = computed(() => ({
  color: tela.value.corLabels || '#262836',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const inputEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampos || '#FFFFFF',
  color: tela.value.corTextoCampos || '#262836',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao || '#ADB4C9',
  color: tela.value.corTextoBotao || '#FFFFFF',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesExtrasEstilo = computed(() => ({
  color: tela.value.corBotoesExtras || '#262836',
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

function atualizarFonte(fonte) {
  if (!fonte) return

  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = storePersonalizacao.personalizacao?.telaLogin?.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

watch(
  () => storePersonalizacao.personalizacao?.telaLogin?.fonte,
  (novaFonte, fonteAntiga) => {
    if (novaFonte !== fonteAntiga) {
      atualizarFonte(novaFonte)
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.login {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #e1e3eb;
  position: relative;
  min-height: 100dvh;
}

section.login.dois {
  justify-content: center;
  align-items: center;
  padding: 80px;
}

section.login.dois .imagem {
  width: 100%;
}

section.login.dois .conteudo {
  width: 100%;
  max-width: 500px;
  min-height: auto;
  padding: 60px 50px;
  border-radius: 10px;
}

section.login.tres {
  justify-content: center;
  align-items: center;
  padding: 80px;
}

section.login.tres .imagem {
  width: 100%;
}

section.login.tres .conteudo .titulo {
  width: 50%;
}

section.login.tres .conteudo .campos {
  width: 50%;
  max-width: 50%;
}

section.login.tres .conteudo {
  flex-direction: row;
  width: 100%;
  min-height: calc(100dvh - 160px);
  padding: 60px 50px;
  border-radius: 10px;
}

.imagem {
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  min-height: 100dvh;
  background-color: #e1e3eb;
  z-index: 2;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo img {
  width: 100%;
  max-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo .titulo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 20px 0;
}

.conteudo .campos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.conteudo label {
  font-family: var(--fonte-tela);
}

.conteudo .inputDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 0 20px 0;
}

.conteudo .inputDefault input {
  padding: 15px;
  font-family: var(--fonte-tela);
  color: #262836;
  background-color: #ffffff;
  border: none;
}

.conteudo .inputDefault input::placeholder {
  opacity: 1;
}

.conteudo label {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 0 0 10px 0;
  color: #262836;
}

.conteudo button.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #ffffff;
  background-color: #adb4c9;
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.principal:hover {
  opacity: 0.7;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 30px 0 0 0;
}

.conteudo .opcoes a {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  margin: 0 10px;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 1000px) {
  section.login {
    flex-direction: column;
  }

  section.login.dois {
    padding: 60px 20px;
  }

  section.login.dois .conteudo {
    padding: 50px 30px;
  }

  section.login.tres {
    flex-direction: column;
    padding: 50px 20px;
  }

  section.login.tres .imagem {
    width: 100%;
  }

  section.login.tres .conteudo .titulo {
    width: 100%;
    padding: 50px 0 0 0;
  }

  section.login.tres .conteudo .campos {
    width: 100%;
    max-width: 100%;
  }

  section.login.tres .conteudo {
    flex-direction: column-reverse;
    min-height: auto;
    padding: 50px 30px;
  }

  .conteudo {
    width: 100%;
    padding: 30px;
  }
}

/* Mobile no Produtor */

.mobile section.login {
  flex-direction: column;
}

.mobile section.login.dois {
  padding: 60px 20px;
}

.mobile section.login.dois .conteudo {
  padding: 50px 30px;
}

.mobile section.login.tres {
  flex-direction: column;
  padding: 50px 20px;
}

.mobile section.login.tres .imagem {
  width: 100%;
}

.mobile section.login.tres .conteudo .titulo {
  width: 100%;
  padding: 50px 0 0 0;
}

.mobile section.login.tres .conteudo .campos {
  width: 100%;
  max-width: 100%;
}

.mobile section.login.tres .conteudo {
  flex-direction: column-reverse;
  min-height: auto;
  padding: 50px 30px;
}

.mobile .conteudo {
  width: 100%;
  padding: 30px;
}
</style>
