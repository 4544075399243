<template>
  <nav ref="menuMobile">
    <Svgs class="logo" nome="logo-flat" @click="irParaRota('/areas')" />
    <!-- OpçÕes -->
    <div class="opcoes" :class="{ ativo: state.opcoesMobile }">
      <button class="opcao" :class="{ ativo: $route.path === '/areas' }" @click="irParaRota('/areas')">
        <Svgs nome="cursor" />
        <p>Suas áreas</p>
      </button>
      <button class="opcao" :class="{ ativo: $route.path === '/notificacoes' }" @click="irParaRota('/notificacoes')">
        <Svgs nome="sino" />
        <p>Notificações</p>
      </button>
      <div class="expandir" :class="{ ativo: state.opcoesFerramentas }" ref="expandirFerramentas">
        <button class="opcao" @click="state.opcoesFerramentas = !state.opcoesFerramentas">
          <Svgs nome="ferramentas" />
          <p>Ferramentas</p>
          <Svgs nome="setinha" class="setinha" />
        </button>
        <div class="balao">
          <div class="free" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
            <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-free.svg" />
            <h3>Você está no plano Free</h3>
            <h4>As ferramentas Level só estão disponíveis para assinantes Essencial, Pro ou Expert.</h4>
          </div>
          <div class="assinante" v-else>
            <button @click="abrirModal('geradorLinkWhatsapp', 'whatsapp', 'Gerador de Link')">
              <img src="https://arquivos.unicpages.com/imagens/app/ferramentas/capa.png" />
              <p>Links para Whatsapp</p>
            </button>
            <button @click="abrirModal('geradorQrCode', 'ferramentas', 'Gerador de QRcode')">
              <img src="https://arquivos.unicpages.com/imagens/app/ferramentas/capa-qrcode.png" />
              <p>Gerador de QRcode</p>
            </button>
          </div>
        </div>
      </div>
      <div class="expandir" :class="{ ativo: state.opcoesSuporte }" ref="expandirSuporte">
        <button class="opcao" @click="state.opcoesSuporte = !state.opcoesSuporte">
          <Svgs nome="duvida" />
          <p>Ajuda</p>
          <Svgs nome="setinha" class="setinha" />
        </button>
        <div class="balao">
          <a href="https://api.whatsapp.com/send?phone=1831993340&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20na%20Level%20Member" target="_blank">
            <Svgs nome="whatsapp" />
            <p>Suporte no Whatsapp</p>
          </a>
          <a href="https://www.youtube.com/@levelmember" target="_blank">
            <Svgs nome="play" />
            <p>Tutoriais no Youtube</p>
          </a>
          <a href="https://instagram.com/levelmember" target="_blank">
            <Svgs nome="instagram" />
            <p>Dicas no Instagram</p>
          </a>
          <a href="https://www.linkedin.com/company/levelmember" target="_blank">
            <Svgs nome="linkedin" />
            <p>Nosso Linkedin</p>
          </a>
        </div>
      </div>
      <div class="expandir" :class="{ ativo: state.opcoesAssinatura }" ref="expandirAssinatura">
        <button class="opcao" @click="state.opcoesAssinatura = !state.opcoesAssinatura">
          <Svgs nome="estrela" />
          <p>Assinatura</p>
          <Svgs nome="setinha" class="setinha" />
        </button>
        <div class="balao">
          <button @click="irParaRota('/planos')">
            <Svgs nome="estrela" />
            <p>Planos Level</p>
          </button>
          <button @click="irParaRota('/afiliados')">
            <Svgs nome="dinheiro" />
            <p>Indique a ganhe</p>
          </button>
        </div>
      </div>
    </div>
    <!-- Plano -->
    <button class="plano" @click="irParaRota('/planos')">
      <img :src="`https://arquivos.levelmember.com/imagens/planos/${storePerfil.usuario?.assinatura?.plano || 'free'}-ico.svg`" />
      <p>{{ storePerfil.usuario?.assinatura?.plano }}</p>
    </button>
    <!-- Perfil -->
    <div class="perfil" :class="{ ativo: state.opcoesPerfil }" ref="expandirPerfil">
      <button class="abrir" @click="state.opcoesPerfil = !state.opcoesPerfil">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <span></span>
      </button>
      <div class="balao">
        <button @click="alterarTema()">
          <Svgs nome="tema" />
          <p>Modo {{ textoTema() }}</p>
        </button>
        <button @click="abrirModal('editarPerfil', 'editar', 'Editar Perfil')">
          <Svgs nome="editar" />
          <p>Editar Perfil</p>
        </button>
        <button @click="abrirModal('alterarSenha', 'cadeado', 'Alterar Senha')">
          <Svgs nome="cadeado" />
          <p>Alterar Senha</p>
        </button>
        <div class="linha"></div>
        <button @click="sair()">
          <Svgs nome="sair" />
          <p>Sair</p>
        </button>
      </div>
    </div>
    <!-- Menu Mobile -->
    <button class="menu" @click="state.opcoesMobile = !state.opcoesMobile">
      <Svgs nome="menu" />
    </button>
    <!-- Modals -->
    <Teleport to="body">
      <ModalEditarPerfil />
      <ModalAlterarSenha />
      <ModalLinkWhatsapp />
      <ModalGerarQrCode />
    </Teleport>
  </nav>
</template>

<script setup>
import Svgs from '@svgs'
import { reactive, ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStorePerfil, useStoreModal } from '@stores'
import { helperClicouFora } from '@helpers'
import ModalEditarPerfil from '@components/app/perfil/modals/ModalEditarPerfil.vue'
import ModalAlterarSenha from '@components/app/perfil/modals/ModalAlterarSenha.vue'
import ModalLinkWhatsapp from '@components/app/ferramentas/modals/ModalLinkWhatsapp.vue'
import ModalGerarQrCode from '@components/app/ferramentas/modals/ModalGerarQrCode.vue'

const router = useRouter()
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const emitter = inject('emitter')

const expandirPerfil = ref(null)
const expandirSuporte = ref(null)
const expandirFerramentas = ref(null)
const expandirAssinatura = ref(null)
const menuMobile = ref(null)

const state = reactive({
  opcoesPerfil: false,
  opcoesSuporte: false,
  opcoesFerramentas: false,
  opcoesAssinatura: false,
  opcoesMobile: false,
  tema: JSON.parse(localStorage.getItem('personalizacao-level-member'))?.tema
})

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
  fecharOpcoes()
}

function sair() {
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Você saiu de sua conta Unic' })
  localStorage.removeItem('level-member-produtor-token')
  router.push('/')
}

function textoTema() {
  if (state.tema === 'claro') return 'Escuro'
  else if (state.tema === 'escuro') return 'Claro'
  else return 'Cor'
}

function alterarTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-level-member')) || {}
  state.tema = state.tema === 'escuro' ? 'claro' : 'escuro'
  localStorage.setItem('personalizacao-level-member', JSON.stringify({ ...dadosLocalStorage, tema: state.tema }))
  document.body.setAttribute('tema', state.tema)

  alterarCoresMeta(state.tema)
}

function alterarCoresMeta(tema) {
  const metaTags = {
    'theme-color': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'msapplication-TileColor': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'msapplication-navbutton-color': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'apple-mobile-web-app-status-bar-style': tema === 'claro' ? '#FFFFFF' : '#0C0C0E'
  }

  for (const [name, content] of Object.entries(metaTags)) {
    let metaTag = document.querySelector(`meta[name="${name}"]`)
    if (!metaTag) {
      metaTag = document.createElement('meta')
      metaTag.name = name
      document.head.appendChild(metaTag)
    }
    metaTag.content = content
  }
}

function fecharOpcoes() {
  state.opcoesPerfil = false
  state.opcoesSuporte = false
  state.opcoesFerramentas = false
  state.opcoesAssinatura = false
}

function irParaRota(rota) {
  fecharOpcoes()
  router.push(rota)
}

helperClicouFora.clicouFora([
  {
    alvo: expandirPerfil,
    retornoChamada: () => (state.opcoesPerfil = false)
  },
  {
    alvo: expandirSuporte,
    retornoChamada: () => (state.opcoesSuporte = false)
  },
  {
    alvo: expandirFerramentas,
    retornoChamada: () => (state.opcoesFerramentas = false)
  },
  {
    alvo: expandirAssinatura,
    retornoChamada: () => (state.opcoesAssinatura = false)
  },
  {
    alvo: menuMobile,
    retornoChamada: () => (state.opcoesMobile = false)
  }
])
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

svg.logo {
  width: 140px;
  min-width: 140px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.opcoes {
  display: flex;
  align-items: center;
  width: 100%;
}

.opcao {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 10px;
  border-radius: 50px;
  transition: all 0.3s;
}

.opcao:hover {
  opacity: 0.7;
}

.opcao.ativo {
  background-color: var(--cor-cinza-3);
  pointer-events: none;
}

.opcao.ativo p {
  color: var(--cor-cinza-5);
}

.opcao.ativo svg {
  fill: var(--cor-cinza-5);
}

.opcao svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 8px 0 0;
  transition: all 0.3s;
}

.opcao svg.setinha {
  margin: 0 0 0 5px;
  fill: var(--cor-cinza-5);
}

.opcao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.expandir {
  position: relative;
}

.expandir .balao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  position: absolute;
  top: 60px;
  left: 0;
  width: 220px;
  min-width: 220px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.expandir.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.expandir .balao .free {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0;
}

.expandir .balao .free img {
  width: 100%;
  max-width: 30px;
}

.expandir .balao .free h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0 5px 0;
}

.expandir .balao .free h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.expandir .balao button,
.expandir .balao a {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.expandir .balao button:hover,
.expandir .balao a:hover {
  opacity: 0.7;
}

.expandir .balao button svg,
.expandir .balao a svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.expandir .balao button img {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 5px;
  margin: 0 10px 0 0;
}

.expandir .balao button p,
.expandir .balao a p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.plano {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  border-radius: 50px;
  padding: 10px 15px 10px 10px;
  margin-left: auto;
  transition: all 0.3s;
}

.plano:hover {
  background-color: var(--cor-cinza-4);
}

.plano img {
  width: 100%;
  max-width: 15px;
  min-width: 15px;
  max-height: 15px;
  margin: 0 5px 0 0;
}

.plano p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.perfil {
  display: flex;
  position: relative;
}

.perfil .abrir {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.perfil .abrir:hover {
  opacity: 0.7;
}

.perfil .abrir .foto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-cinza-4);
  background-size: cover;
  background-position: center;
}

.perfil .abrir span {
  width: 10px;
  height: 10px;
  background-color: var(--cor-verde);
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

.perfil .balao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  position: absolute;
  top: 55px;
  right: 0;
  width: 200px;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.perfil.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.perfil .balao .linha {
  width: 100%;
  height: 2px;
  background-color: var(--cor-cinza-3);
  margin: 10px 0;
}

.perfil .balao button {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.perfil .balao button:hover {
  opacity: 0.7;
}

.perfil .balao button svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.perfil .balao button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.menu {
  display: none;
  background-color: transparent;
  margin: 0 0 0 20px;
}

.menu svg {
  width: 20px;
  min-height: 20px;
  fill: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .opcoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: var(--cor-cinza-2);
    position: fixed;
    top: 50px;
    left: 0;
    padding: 20px 20px 20px 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s;
  }

  .opcoes.ativo {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .opcoes .opcao {
    margin: 5px 0;
    padding: 10px;
  }

  .expandir {
    position: relative;
  }

  .expandir .balao {
    display: none;
    background-color: transparent;
    border: none;
    padding: 0 0 0 20px;
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    min-width: 100%;
  }

  .expandir.ativo .balao {
    display: flex;
    animation: fadeIn 0.3s linear;
  }

  .perfil .abrir .foto {
    width: 35px;
    height: 35px;
  }

  .menu {
    display: flex;
  }
}
</style>
