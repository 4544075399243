import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()
const idProdutor = helperToken.idProdutor()

export default {
  receberTodos(idArea) {
    const method = 'GET'
    const url = `alunos/${idProdutor}/${idArea}`

    return api(method, url, null, token)
  },

  receberPorId(idArea, idAluno) {
    const method = 'GET'
    const url = `alunos/${idProdutor}/${idArea}/${idAluno}`

    return api(method, url, null, token)
  },

  adicionar(idArea, payload) {
    const method = 'POST'
    const url = `alunos/${idProdutor}/${idArea}`
    const dados = payload

    return api(method, url, dados, token)
  },

  deletar(idArea, idAluno) {
    const method = 'DELETE'
    const url = `alunos/${idProdutor}/${idArea}/${idAluno}`

    return api(method, url, null, token)
  },

  resetarSenha(idArea, idAluno) {
    const method = 'PATCH'
    const url = `alunos/senha/resetar/${idProdutor}/${idArea}/${idAluno}`
    const dados = {}

    return api(method, url, dados, token)
  },

  alterarAcesso(idArea, idAluno, idCurso, payload) {
    const method = 'PATCH'
    const url = `alunos/acesso/alterar/${idProdutor}/${idArea}/${idAluno}/${idCurso}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
