import { defineStore } from 'pinia'

export const useStorePersonalizacao = defineStore('storePersonalizacao', {
  state: () => ({
    telaSelecionada: '',
    personalizacao: {
      telaLogin: {
        layout: '',
        fonte: '',
        corFundo: '',
        corQuadro: '',
        corTitulo: '',
        corTextoBotao: '',
        corFundoBotao: '',
        corLabels: '',
        corFundoCampos: '',
        corTextoCampos: '',
        corBotoesExtras: '',
        textoTitulo: '',
        textoBotaoEntrar: '',
        textoBotaoExtraUm: '',
        textoBotaoExtraDois: '',
        imagemLogo: '',
        imagemFundo: '',
        linkBotaoExtraUm: '',
        linkBotaoExtraDois: ''
      },
      telaAlterarSenha: {
        layout: '',
        fonte: '',
        corFundo: '',
        corTitulo: '',
        corTextoBotao: '',
        corFundoBotao: '',
        corLabels: '',
        corFundoCampos: '',
        corTextoCampos: '',
        corBotoesExtras: '',
        textoTitulo: '',
        textoBotaoAlterar: '',
        textoBotaoExtra: '',
        imagemLogo: '',
        imagemFundo: '',
        linkBotaoExtra: ''
      },
      telaLoading: {
        fonte: '',
        corFundo: '',
        corTitulo: '',
        corLoading: '',
        textoTitulo: '',
        imagemLogo: '',
        imagemFundo: ''
      },
      telaCursosArea: {
        layout: '',
        fonte: '',
        corFundo: '',
        corQuadro: '',
        corTextos: '',
        corTextosDois: '',
        corFundoBotao: '',
        corTextoBotao: '',
        corProgressoFundo: '',
        corProgressoAtual: '',
        corTextoCapa: '',
        textoCapaTitulo: '',
        textoCapaDescricao: '',
        textoCapaBotao: '',
        textoBotao: '',
        textoAulas: '',
        imagemCapaDesktop: '',
        imagemCapaMobile: '',
        imagemBannerDesktop: '',
        imagemBannerMobile: '',
        linkBanner: '',
        linkCapa: ''
      },
      telaCurso: {
        layout: '',
        fonte: '',
        corFundo: '',
        corQuadro: '',
        corTitulos: '',
        corTags: '',
        corFundoBotao: '',
        corTextoBotao: '',
        corPosicaoTexto: '',
        corPosicaoFundo: '',
        corCaminho: '',
        textoBotao: '',
        imagemCapa: '',
        imagemCapaMobile: ''
      },
      telaModulo: {
        layout: '',
        fonte: '',
        corFundo: '',
        corQuadro: '',
        corTitulos: '',
        corTags: '',
        corFundoBotao: '',
        corTextoBotao: '',
        corPosicaoTexto: '',
        corPosicaoFundo: '',
        corCaminho: '',
        textoBotao: '',
        imagemCapa: '',
        imagemCapaMobile: ''
      },
      telaAula: {
        fonte: '',
        corFundo: '',
        corTitulo: '',
        corDescricao: '',
        corDuracao: '',
        corLinks: '',
        corArquivos: '',
        corPosicaoTexto: '',
        corPosicaoFundo: '',
        corFundoCampoComentario: '',
        corTextoCampoComentario: '',
        corTextosComentarios: '',
        corTextoBotaoComentar: '',
        corFundoBotaoComentar: '',
        corTextoBotaoResponder: '',
        corFundoBotaoResponder: '',
        corTextoDataComentario: '',
        corFundoAula: '',
        corTextoAula: '',
        corSvgBotaoAula: '',
        corFundoBotaoAula: '',
        corTextoPosicaoAula: '',
        corFundoPosicaoAula: '',
        corTextoDuracaoAula: '',
        corFundoDuracaoAula: ''
      },
      telaPerfil: {
        layout: '',
        fonte: '',
        corFundo: '',
        corNome: '',
        corEmail: '',
        corBotoesFundo: '',
        corBotoesTexto: '',
        imagemCapa: ''
      },
      menu: {
        layout: '',
        fonte: '',
        corFundo: '',
        corTitulo: '',
        corBotoes: '',
        corIcones: '',
        textoTitulo: '',
        textoBotaoExtraUm: '',
        textoBotaoExtraDois: '',
        textoBotaoExtraTres: '',
        imagemLogo: '',
        imagemBotaoExtraUm: '',
        imagemBotaoExtraDois: '',
        imagemBotaoExtraTres: '',
        linkBotaoExtraUm: '',
        linkBotaoExtraDois: '',
        linkBotaoExtraTres: ''
      }
    }
  }),
  actions: {
    carregarLogin(novosDados) {
      this.personalizacao.telaLogin = { ...novosDados }
    },
    carregarAlterarSenha(novosDados) {
      this.personalizacao.telaAlterarSenha = { ...novosDados }
    },
    carregarLoading(novosDados) {
      this.personalizacao.telaLoading = { ...novosDados }
    },
    carregarErro(novosDados) {
      this.personalizacao.telaErro = { ...novosDados }
    },
    carregarInicio(novosDados) {
      this.personalizacao.telaInicio = { ...novosDados }
    },
    carregarCurso(novosDados) {
      this.personalizacao.telaCurso = { ...novosDados }
    },
    carregarModulo(novosDados) {
      this.personalizacao.telaModulo = { ...novosDados }
    },
    carregarAula(novosDados) {
      this.personalizacao.telaAula = { ...novosDados }
    },
    carregarPerfil(novosDados) {
      this.personalizacao.telaPerfil = { ...novosDados }
    },
    carregarMenu(novosDados) {
      this.personalizacao.menu = { ...novosDados }
    }
  }
})
