<template>
  <section class="ajustes" @mouseenter="adicionarClasseBloqueado" @mouseleave="removerClasseBloqueado">
    <div class="edicoes" :class="{ aparecer: state.mostrarAjustesMobile }">
      <div class="vazio" v-if="isTudoFalso">
        <Svgs nome="logo-flat" />
        <p>Selecione alguma tela da área para mostrar as opções de edição nessa lateral</p>
      </div>

      <AjustesLogin v-if="storePersonalizacao.telaSelecionada === 'telaLogin'" />
      <AjustesSenha v-if="storePersonalizacao.telaSelecionada === 'telaAlterarSenha'" />
      <AjustesLoading v-if="storePersonalizacao.telaSelecionada === 'telaLoading'" />
      <AjustesCursos v-if="storePersonalizacao.telaSelecionada === 'telaCursosArea'" />
      <AjustesCurso v-if="storePersonalizacao.telaSelecionada === 'telaCurso'" />
      <AjustesModulo v-if="storePersonalizacao.telaSelecionada === 'telaModulo'" />
      <AjustesAula v-if="storePersonalizacao.telaSelecionada === 'telaAula'" />
      <AjustesPerfil v-if="storePersonalizacao.telaSelecionada === 'telaPerfil'" />
      <AjustesMenu v-if="storePersonalizacao.telaSelecionada === 'menu'" />
    </div>
    <button class="mostrar" :class="{ ativo: state.mostrarAjustesMobile }" @click="state.mostrarAjustesMobile = !state.mostrarAjustesMobile">
      <Svgs nome="setinha" />
    </button>
  </section>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStorePersonalizacao } from '@stores'
import Svgs from '@svgs'
import AjustesLogin from '../ajustes/Login.vue'
import AjustesSenha from '../ajustes/Senha.vue'
import AjustesLoading from '../ajustes/Loading.vue'
import AjustesCursos from '../ajustes/Cursos.vue'
import AjustesCurso from '../ajustes/Curso.vue'
import AjustesModulo from '../ajustes/Modulo.vue'
import AjustesAula from '../ajustes/Aula.vue'
import AjustesPerfil from '../ajustes/Perfil.vue'
import AjustesMenu from '../ajustes/Menu.vue'

const storePersonalizacao = useStorePersonalizacao()

const state = reactive({
  mostrarAjustesMobile: false
})

const acessoDesktop = ref(window.innerWidth >= 1024)

const isTudoFalso = computed(() => {
  return storePersonalizacao.telaSelecionada === ''
})

function adicionarClasseBloqueado() {
  document.body.classList.add('bloqueado')
}

function removerClasseBloqueado(event) {
  if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
    document.body.classList.remove('bloqueado')
  }
}

function verificarTela() {
  acessoDesktop.value = window.innerWidth >= 1024
}

onMounted(() => {
  window.addEventListener('resize', verificarTela)
})

onUnmounted(() => {
  window.removeEventListener('resize', verificarTela)
  document.body.classList.remove('bloqueado')
})

watch(
  () => state.mostrarAjustesMobile,
  (novoValor) => {
    if (novoValor) {
      document.body.classList.add('bloqueado')
    } else {
      document.body.classList.remove('bloqueado')
    }
  }
)
</script>

<style scoped>
section.ajustes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

.edicoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  padding: 0 0 30px 0;
  transition: all 0.5s;
}

.edicoes::-webkit-scrollbar-track {
  background-color: transparent;
}

.vazio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100dvh;
  animation: fadeIn 0.3s ease-in-out;
}

.vazio svg {
  width: 100px;
  min-width: 100px;
  fill: var(--cor-cinza-5);
  margin: 0 0 10px 0;
}

.vazio p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 200px;
}

button.mostrar {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px 10px 0 0;
  transition: all 0.3s;
}

button.mostrar:hover {
  background-color: var(--cor-cinza-3);
}

button.mostrar svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  transform: rotate(180deg);
}

button.mostrar.ativo svg {
  transform: rotate(0deg);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.ajustes {
    top: auto;
    bottom: 0;
    right: 0;
    min-height: auto;
    max-height: auto;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .edicoes {
    height: 0dvh;
    padding: 20px 0 30px 0;
  }

  .edicoes.aparecer {
    height: 42dvh;
  }

  button.mostrar {
    display: flex;
  }
}
</style>
