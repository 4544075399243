<template>
  <section class="ajustes">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="cursor" />
      </div>
      <p>Tela dos Cursos</p>
    </div>

    <!-- Layout -->
    <label>Layout</label>
    <div class="layouts">
      <button :class="{ ativo: tela.layout === 'um' }" @click="tela.layout = 'um'">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/default/telas/layout-inicio-1.svg')"></div>
        <p>Modelo 1</p>
      </button>
      <button :class="{ ativo: tela.layout === 'dois' }" @click="tela.layout = 'dois'">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/default/telas/layout-inicio-2.svg')"></div>
        <p>Modelo 2</p>
      </button>
      <button :class="{ ativo: tela.layout === 'tres' }" @click="tela.layout = 'tres'">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/default/telas/layout-inicio-3.svg')"></div>
        <p>Modelo 3</p>
      </button>
      <button :class="{ ativo: tela.layout === 'quatro' }" @click="tela.layout = 'quatro'">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/default/telas/layout-inicio-4.svg')"></div>
        <p>Modelo 4</p>
      </button>
    </div>

    <!-- Fonte -->
    <label>Fonte</label>
    <FontPicker :valor="tela.fonte" :onAtualizarFonte="(novaFonte) => atualizarFonte('fonte', novaFonte)" />

    <!-- Textos -->
    <label>Texto Capa Título</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoCapaTitulo" @input="atualizarTexto('textoCapaTitulo')" v-redimensionar-textarea></textarea>

    <label>Texto Capa Descrição</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoCapaDescricao" @input="atualizarTexto('textoCapaDescricao')" v-redimensionar-textarea></textarea>

    <label>Texto Capa Botão</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoCapaBotao" @input="atualizarTexto('textoCapaBotao')" v-redimensionar-textarea></textarea>

    <label>Texto do Botão</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoBotao" @input="atualizarTexto('textoBotao')" v-redimensionar-textarea></textarea>

    <label>Texto Aulas</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoAulas" @input="atualizarTexto('textoAulas')" v-redimensionar-textarea></textarea>

    <!-- Links -->
    <label>
      <Svgs nome="link" />
      Link Capa
    </label>
    <textarea speelcheck="false" rows="1" placeholder="https://link.com" v-model="tela.linkCapa" @input="atualizarTexto('linkCapa')" v-redimensionar-textarea></textarea>

    <label>
      <Svgs nome="link" />
      Link Banner
    </label>
    <textarea speelcheck="false" rows="1" placeholder="https://link.com" v-model="tela.linkBanner" @input="atualizarTexto('linkBanner')" v-redimensionar-textarea></textarea>

    <!-- Cores -->
    <label>Cor Fundo</label>
    <ColorPicker :valor="tela.corFundo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundo', novaCor)" />

    <label>Cor Quadro</label>
    <ColorPicker :valor="tela.corQuadro" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corQuadro', novaCor)" />

    <label>Cor Textos</label>
    <ColorPicker :valor="tela.corTextos" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextos', novaCor)" />

    <label>Cor Textos Secundários</label>
    <ColorPicker :valor="tela.corTextosDois" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextosDois', novaCor)" />

    <label>Cor Fundo do Botão</label>
    <ColorPicker :valor="tela.corFundoBotao" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundoBotao', novaCor)" />

    <label>Cor Texto do Botão</label>
    <ColorPicker :valor="tela.corTextoBotao" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoBotao', novaCor)" />

    <label>Cor de Progresso Fundo</label>
    <ColorPicker :valor="tela.corProgressoFundo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corProgressoFundo', novaCor)" />

    <label>Cor de Progresso Atual</label>
    <ColorPicker :valor="tela.corProgressoAtual" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corProgressoAtual', novaCor)" />

    <label>Cor Texto da Capa</label>
    <ColorPicker :valor="tela.corTextoCapa" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTextoCapa', novaCor)" />

    <!-- Imagens -->
    <label>Imagem Capa Desktop</label>
    <UploadArquivo :valor="tela.imagemCapaDesktop" :onAtualizarImagem="(novaImagem) => atualizarImagem('imagemCapaDesktop', novaImagem)" :defaultSize="'1920x1080'" :uploadText="'Subir Imagem Capa Desktop'" :maxWidth="1920" />

    <label>Imagem Capa Mobile</label>
    <UploadArquivo :valor="tela.imagemCapaMobile" :onAtualizarImagem="(novaImagem) => atualizarImagem('imagemCapaMobile', novaImagem)" :defaultSize="'700x300'" :uploadText="'Subir Imagem Capa Mobile'" :maxWidth="700" />

    <label>Imagem Banner Desktop</label>
    <UploadArquivo :valor="tela.imagemBannerDesktop" :onAtualizarImagem="(novaImagem) => atualizarImagem('imagemBannerDesktop', novaImagem)" :defaultSize="'1920x1080'" :uploadText="'Subir Imagem Banner Desktop'" :maxWidth="1920" />

    <label>Imagem Banner Mobile</label>
    <UploadArquivo :valor="tela.imagemBannerMobile" :onAtualizarImagem="(novaImagem) => atualizarImagem('imagemBannerMobile', novaImagem)" :defaultSize="'700x300'" :uploadText="'Subir Imagem Banner Mobile'" :maxWidth="700" />

    <div class="salvar">
      <button @click="salvar()">
        <Svgs nome="subir" />
        <p>Salvar Alterações</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStorePersonalizacao, useStoreAreas } from '@stores'
import FontPicker from '@components/global/elementos/FontPicker.vue'
import ColorPicker from '@components/global/elementos/ColorPicker.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'

const storePersonalizacao = useStorePersonalizacao()
const storeAreas = useStoreAreas()

const tela = computed(() => storePersonalizacao.personalizacao?.telaCursosArea || {})

function atualizarCorSelecionada(propriedade, novaCor) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = `#${novaCor}`
  }
}

function atualizarFonte(propriedade, novaFonte) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = novaFonte
  }
}

function atualizarTexto(propriedade) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = tela.value[propriedade] || ''
  }
}

function atualizarImagem(propriedade, novaImagem) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = novaImagem
  }
}

async function salvar() {
  const idArea = storeAreas.area._id

  const payload = {
    personalizacao: {
      telaCursosArea: tela.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(idArea, payload)
  if (editadoOk !== true) return
}
</script>

<style scoped>
section.ajustes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 100px 20px;
  width: 100%;
  overflow-y: scroll;
}
label {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
}
label svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}
textarea {
  background-color: var(--cor-cinza-3);
}
textarea:focus {
  background-color: var(--cor-cinza-4);
}
.layouts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 135px;
  overflow-x: scroll;
}
.layouts button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  min-width: 160px;
  margin: 0 15px 0 0;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  padding: 0 0 15px 0;
  transition: all 0.3s;
}
.layouts button.ativo {
  border: 1px solid var(--cor-laranja);
}
.layouts button .capa {
  width: 100%;
  height: 90px;
  background-size: cover;
  background-position: center;
}
.layouts button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 0 0;
}
.titulo {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0 0 0;
}
.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--cor-cinza-3);
}
.titulo .icone svg {
  width: 13px;
  min-width: 13px;
  max-height: 13px;
  fill: var(--cor-branco);
}
.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
}
.salvar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 250px;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  z-index: 7;
}
.salvar button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--cor-laranja);
  padding: 15px 12px;
  width: 100%;
  transition: all 0.3s;
}
.salvar button:hover {
  background-color: var(--cor-laranja-escuro);
}
.salvar button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}
.salvar button svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}
</style>
