<template>
  <Modal nome="criarAula">
    <label class="um">
      Nome da aula
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Aula de Marketing" @keydown.enter="criarAula" />

    <label>Descrição da aula</label>
    <textarea v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Aula abordando o tema marketing" v-redimensionar-textarea></textarea>

    <label>Duração da aula em minutos</label>
    <input v-model="state.duracao" type="number" spellCheck="false" autocomplete="false" placeholder="0" />

    <label class="link">
      URL do Vídeo
      <span>*</span>
      <button>Onde conseguir?</button>
    </label>
    <textarea v-model="conteudoTextarea" @input="filtrarInput" type="text" spellCheck="false" autocomplete="false" placeholder="https://video.com" v-redimensionar-textarea></textarea>

    <div class="video" v-if="codigoIframe">
      <div class="tamanho">
        <iframe :src="codigoIframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
      </div>
    </div>

    <label>
      Imagem de capa da aula
      <span>*</span>
    </label>
    <UploadArquivo :valor="state.imagemCapa" :onAtualizarImagem="atualizarImagemCapa" :defaultSize="'1280x720'" :uploadText="'Subir Capa'" :maxWidth="1280" />

    <label>
      Módulo da aula
      <span>*</span>
    </label>
    <div class="select">
      <Svgs nome="setinha" />
      <select v-model="state.idModulo">
        <option v-for="(modulo, index) in storeModulos.modulos" :key="index" :value="modulo._id">Módulo {{ modulo.nome }}</option>
      </select>
    </div>

    <SalvarModal nome="Criar Nova Aula" @click="criarAula()" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, computed, watch, ref } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModal, useStoreModulos, useStoreAulas } from '@stores'
import { helperTexto } from '@helpers'

import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeAulas = useStoreAulas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const storeModulos = useStoreModulos()

const dadosModal = computed(() => storeModal.dadosDoModal('criarAula'))

const conteudoTextarea = ref('')
const codigoIframe = ref('')

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  imagemCapa: '',
  urlVideo: '',
  duracao: 0,
  idModulo: ''
})

function atualizarImagemCapa(novaUrl) {
  state.imagemCapa = novaUrl
}

function nomeParaHotlink() {
  state.hotlink = helperTexto.converterEmHotlink(state.nome)
}

async function criarAula() {
  if (!validarDados()) return

  nomeParaHotlink()

  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = state.idModulo

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink,
    imagemCapa: state.imagemCapa,
    duracao: state.duracao,
    urlVideo: state.urlVideo
  }

  const adicionadoOk = await storeAulas.adicionar(idArea, idCurso, idModulo, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarAulas')
  storeModal.fecharModal('criarAula')
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.imagemCapa) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

const filtrarInput = async () => {
  const pegarIframe = conteudoTextarea.value.match(/<iframe.*?src="(.*?)"/)
  const pegarUrl = conteudoTextarea.value.match(/https?:\/\/[^\s]+/)

  if (pegarIframe) {
    codigoIframe.value = pegarIframe[1]
    state.urlVideo = codigoIframe.value
  } else if (pegarUrl) {
    codigoIframe.value = pegarUrl[0]
    state.urlVideo = codigoIframe.value
  } else {
    codigoIframe.value = ''
    state.urlVideo = ''
  }

  if (state.urlVideo.includes('youtube.com') || state.urlVideo.includes('youtu.be')) {
    const videoIdMatch = state.urlVideo.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      state.imagemCapa = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      state.urlVideo = `https://www.youtube.com/embed/${videoId}`
      codigoIframe.value = state.urlVideo
    }
  }

  if (state.urlVideo.includes('pandavideo.com.br')) {
    const videoIdMatch = state.urlVideo.match(/\/v=([a-z0-9-]+)/i) || state.urlVideo.match(/\/embed\/\?v=([a-z0-9-]+)/i)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      const servidorId = 'b-vz-cce5a623-141'
      state.imagemCapa = `https://${servidorId}.tv.pandavideo.com.br/${videoId}/thumbnail.jpg`
    }
  }

  if (state.urlVideo.includes('vimeo.com')) {
    const videoIdMatch = state.urlVideo.match(/video\/(\d+)/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      try {
        const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
        const data = await response.json()
        state.imagemCapa = data[0].thumbnail_large
      } catch {}
    }
  }
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.imagemCapa = ''
  state.duracao = 0
  state.urlVideo = ''
  state.idModulo = ''
  codigoIframe.value = ''
  conteudoTextarea.value = ''
}

async function pegarId() {
  if (dadosModal.value.id) {
    state.idModulo = dadosModal.value.id
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarId()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
  width: 100%;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-azul);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

.video {
  width: 100%;
  margin: 20px 0 0 0;
}

.video .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.video .tamanho iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: var(--cor-preto-fixo);
}

button.salvar {
  margin: 20px 0 0 0;
}
</style>
