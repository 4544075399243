<template>
  <section class="ajustes">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="cursor" />
      </div>
      <p>Menu da Área</p>
    </div>

    <!-- Fonte -->
    <label>Fonte dos textos</label>
    <FontPicker :valor="tela.fonte" :onAtualizarFonte="(novaFonte) => atualizarFonte('fonte', novaFonte)" />

    <!-- Textos -->
    <label>Texto do Título</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoTitulo" @input="atualizarTexto('textoTitulo')" v-redimensionar-textarea></textarea>

    <label>Texto do Botão Secundário Um</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoBotaoExtraUm" @input="atualizarTexto('textoBotaoExtraUm')" v-redimensionar-textarea></textarea>

    <label>Texto do Botão Secundário Dois</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoBotaoExtraDois" @input="atualizarTexto('textoBotaoExtraDois')" v-redimensionar-textarea></textarea>

    <label>Texto do Botão Secundário Três</label>
    <textarea speelcheck="false" rows="1" placeholder="Digite aqui" v-model="tela.textoBotaoExtraTres" @input="atualizarTexto('textoBotaoExtraTres')" v-redimensionar-textarea></textarea>

    <!-- Links -->
    <label>
      <Svgs nome="link" />
      Link do Botão Secundário Um
    </label>
    <textarea speelcheck="false" rows="1" placeholder="https://link.com" v-model="tela.linkBotaoExtraUm" @input="atualizarTexto('linkBotaoExtraUm')" v-redimensionar-textarea></textarea>

    <label>
      <Svgs nome="link" />
      Link do Botão Secundário Dois
    </label>
    <textarea speelcheck="false" rows="1" placeholder="https://link.com" v-model="tela.linkBotaoExtraDois" @input="atualizarTexto('linkBotaoExtraDois')" v-redimensionar-textarea></textarea>

    <label>
      <Svgs nome="link" />
      Link do Botão Secundário Três
    </label>
    <textarea speelcheck="false" rows="1" placeholder="https://link.com" v-model="tela.linkBotaoExtraTres" @input="atualizarTexto('linkBotaoExtraTres')" v-redimensionar-textarea></textarea>

    <!-- Cores -->
    <label>Cor Fundo</label>
    <ColorPicker :valor="tela.corFundo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corFundo', novaCor)" />

    <label>Cor Título</label>
    <ColorPicker :valor="tela.corTitulo" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corTitulo', novaCor)" />

    <label>Cor dos Botões</label>
    <ColorPicker :valor="tela.corBotoes" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corBotoes', novaCor)" />

    <label>Cor dos Ícones</label>
    <ColorPicker :valor="tela.corIcones" :onAtualizarCor="(novaCor) => atualizarCorSelecionada('corIcones', novaCor)" />

    <!-- Imagens -->
    <label>Imagem de Logo</label>
    <UploadArquivo :valor="tela.imagemLogo" :onAtualizarImagem="(novaImagem) => atualizarImagem('imagemLogo', novaImagem)" :defaultSize="'700x300'" :uploadText="'Subir Logo'" :maxWidth="700" />

    <div class="salvar">
      <button @click="salvar()">
        <Svgs nome="subir" />
        <p>Salvar Alterações</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStorePersonalizacao, useStoreAreas } from '@stores'
import FontPicker from '@components/global/elementos/FontPicker.vue'
import ColorPicker from '@components/global/elementos/ColorPicker.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'

const storePersonalizacao = useStorePersonalizacao()
const storeAreas = useStoreAreas()

const tela = computed(() => storePersonalizacao.personalizacao?.menu || {})

function atualizarCorSelecionada(propriedade, novaCor) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = `#${novaCor}`
  }
}

function atualizarFonte(propriedade, novaFonte) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = novaFonte
  }
}

function atualizarTexto(propriedade) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = tela.value[propriedade] || ''
  }
}

function atualizarImagem(propriedade, novaImagem) {
  if (tela.value && propriedade) {
    tela.value[propriedade] = novaImagem
  }
}

async function salvar() {
  const idArea = storeAreas.area._id

  const payload = {
    personalizacao: {
      menu: tela.value
    }
  }

  const editadoOk = await storeAreas.alterarDados(idArea, payload)
  if (editadoOk !== true) return
}
</script>

<style scoped>
section.ajustes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 100px 20px;
  width: 100%;
  overflow-y: scroll;
}
label {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
}
label svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}
textarea {
  background-color: var(--cor-cinza-3);
}
textarea:focus {
  background-color: var(--cor-cinza-4);
}
.layouts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 135px;
  overflow-x: scroll;
}
.layouts button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  min-width: 160px;
  margin: 0 15px 0 0;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  padding: 0 0 15px 0;
  transition: all 0.3s;
}
.layouts button.ativo {
  border: 1px solid var(--cor-laranja);
}
.layouts button .capa {
  width: 100%;
  height: 90px;
  background-size: cover;
  background-position: center;
}
.layouts button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 0 0;
}
.titulo {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0 0 0;
}
.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--cor-cinza-3);
}
.titulo .icone svg {
  width: 13px;
  min-width: 13px;
  max-height: 13px;
  fill: var(--cor-branco);
}
.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
}
.salvar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 250px;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  z-index: 7;
}
.salvar button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--cor-laranja);
  padding: 15px 12px;
  width: 100%;
  transition: all 0.3s;
}
.salvar button:hover {
  background-color: var(--cor-laranja-escuro);
}
.salvar button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}
.salvar button svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}
</style>
