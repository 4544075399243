<template>
  <Modal nome="editarArea">
    <div class="conteudo">
      <label class="um">
        Nome da área
        <span>*</span>
      </label>
      <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria Turbo" @keydown.enter="salvar" />

      <label>
        Descrição da área
        <span>*</span>
      </label>
      <input v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria com foco em resultados" @keydown.enter="salvar" />

      <label>
        URL padrão da sua área
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>mber.live/</p>
        <input v-model="state.hotlink" type="text" spellCheck="false" autocomplete="false" @input="validarHotlink()" placeholder="sua-area" @keydown.enter="salvar" />
      </div>

      <label>
        Logo da área
        <span>*</span>
      </label>
      <UploadArquivo :valor="state.imagemLogo" :onAtualizarImagem="atualizarImagemLogo" :defaultSize="'500x200'" :uploadText="'Subir Logo'" :maxWidth="500" />

      <label>
        Imagem de capa da área
        <span>*</span>
      </label>
      <UploadArquivo :valor="state.imagemCapa" :onAtualizarImagem="atualizarImagemCapa" :defaultSize="'1920x1080'" :uploadText="'Subir Capa'" :maxWidth="1920" />

      <label>
        Senha padrão dos alunos
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input v-model="state.senhaPadraoAlunos" type="text" spellCheck="false" autocomplete="false" @input="validarSenhaAlunos()" placeholder="Digite a senha aqui..." @keydown.enter="salvar" />
      </div>

      <label>
        E-mail de suporte da área
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="envelope" />
        <input v-model="state.emailSuporte" type="email" spellCheck="false" autocomplete="false" placeholder="email@email.com" @keydown.enter="salvar" />
      </div>

      <SalvarModal nome="Salvar Alterações" @click="salvar" />
    </div>
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, watch, computed } from 'vue'
import { useStoreAreas, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('editarArea'))

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  emailSuporte: '',
  senhaPadraoAlunos: '',
  imagemCapa: '',
  imagemLogo: ''
})

function atualizarImagemLogo(novaUrl) {
  state.imagemLogo = novaUrl
}

function atualizarImagemCapa(novaUrl) {
  state.imagemCapa = novaUrl
}

function validarHotlink() {
  let hotlink = state.hotlink

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlink = hotlink
}

function validarSenhaAlunos() {
  let senha = state.senhaAlunos

  senha = senha.replace(/\s+/g, '-')

  senha = senha.replace(/[^a-zA-Z0-9-_.~!*'();:@&=+$,/?%#\[\]]/g, '')

  state.senhaAlunos = senha
}

async function salvar() {
  if (!validarDados()) return

  const idArea = storeAreas.area?._id

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink?.trim(),
    imagemCapa: state.imagemCapa,
    imagemLogo: state.imagemLogo,
    senhaPadraoAlunos: state.senhaPadraoAlunos,
    emailSuporte: state.emailSuporte
  }

  const adicionadoOk = await storeAreas.alterarDados(idArea, payload)
  if (adicionadoOk !== true) return

  setTimeout(() => {
    window.open(`/areas/${payload.hotlink}`, '_self')
  }, 1000)
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.hotlink || !state.emailSuporte) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.emailSuporte = ''
  state.senhaPadraoAlunos = ''
  state.imagemCapa = ''
  state.imagemLogo = ''
}

function preencherDados() {
  state.nome = storeAreas.area?.nome
  state.descricao = storeAreas.area?.descricao
  state.hotlink = storeAreas.area?.hotlink
  state.emailSuporte = storeAreas.area?.emailSuporte
  state.senhaPadraoAlunos = storeAreas.area?.senhaPadraoAlunos
  state.imagemCapa = storeAreas.area?.imagemCapa
  state.imagemLogo = storeAreas.area?.imagemLogo
}

async function pegarIdArea() {
  if (dadosModal.value.id) {
    await storeAreas.receberPorId(dadosModal.value.id)
    preencherDados()
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdArea()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

.cor {
  width: 100%;
  position: relative;
}

.formatos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.formatos button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 5px);
  border-radius: 12px;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  transition: all 0.3s;
}

.formatos button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  min-height: 110px;
  border-radius: 10px 10px 0 0;
  background-color: var(--cor-cinza-4);
  transition: all 0.3s;
}

.formatos button .icone svg {
  width: 60px;
  min-width: 60px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.formatos button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
}

.formatos button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.formatos button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 10px 0;
}

.formatos button.ativo {
  border: 1px solid var(--cor-laranja);
}

.formatos button.ativo .icone {
  background-color: var(--cor-laranja);
}

.formatos button.ativo .icone svg {
  fill: var(--cor-branco-fixo);
}

.temas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.temas button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 5px);
  border-radius: 12px;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  transition: all 0.3s;
}

.temas button.ativo {
  border: 1px solid var(--cor-laranja);
}

.temas button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  min-height: 110px;
  border-radius: 10px 10px 0 0;
  transition: all 0.3s;
}

.temas button.claro .icone {
  background-color: var(--cor-branco-fixo);
}

.temas button.claro .icone svg {
  fill: var(--cor-preto-fixo);
}

.temas button.escuro .icone {
  background-color: var(--cor-preto-fixo);
}

.temas button.escuro .icone svg {
  fill: var(--cor-branco-fixo);
}

.temas button.ativo .icone svg {
  fill: var(--cor-laranja);
}

.temas button .icone svg {
  width: 60px;
  min-width: 60px;
  transition: all 0.3s;
}

.temas button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
}

.temas button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.temas button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .titulo .nome h3 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
  }

  .formatos {
    flex-direction: column;
    justify-content: flex-start;
  }

  .formatos button {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
    position: relative;
  }

  .formatos button .capa {
    width: 130px;
    max-width: 130px;
    min-width: 130px;
    height: 75px;
    border-radius: 10px 0 0 10px;
  }

  .formatos button .texto {
    align-items: flex-start;
    text-align: left;
    padding: 0 10px;
  }

  .formatos button .texto h3 {
    font-size: var(--f2);
  }

  .formatos button .texto p {
    font-size: var(--f0);
    line-height: 1.5;
    margin: 5px 0 0 0;
  }

  .formatos button .icone {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .formatos button.listas {
    margin: 10px 0;
  }

  .formatos button .icone svg {
    width: 8px;
    min-width: 8px;
  }

  .temas button {
    width: calc(50% - 5px);
    border-radius: 10px;
  }

  .temas button .capa {
    width: 100%;
    height: 85px;
  }

  .temas button .texto {
    padding: 15px 10px;
  }

  .temas button .texto p {
    font-size: var(--f0);
    max-width: 90px;
    margin: 5px 0 10px 0;
  }

  .temas button .icone {
    width: 20px;
    height: 20px;
  }

  .temas button .icone svg {
    width: 10px;
    min-width: 10px;
  }
}
</style>
