<template>
  <Modal nome="criarArea">
    <div class="conteudo">
      <label class="um">
        Nome da área
        <span>*</span>
      </label>
      <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria Turbo" @keydown.enter="criarArea" />

      <label>
        Descrição da área
        <span>*</span>
      </label>
      <input v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria com foco em resultados" @keydown.enter="criarArea" />

      <label>
        URL padrão da sua área
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>mber.live/</p>
        <input v-model="state.hotlink" type="text" spellCheck="false" autocomplete="false" @input="validarHotlink()" placeholder="sua-area" @keydown.enter="criarArea" />
      </div>

      <label>
        Logo da área
        <span>*</span>
      </label>
      <UploadArquivo :valor="state.imagemLogo" :onAtualizarImagem="atualizarImagemLogo" :defaultSize="'500x200'" :uploadText="'Subir Logo'" :maxWidth="500" />

      <label>
        Imagem de capa da área
        <span>*</span>
      </label>
      <UploadArquivo :valor="state.imagemCapa" :onAtualizarImagem="atualizarImagemCapa" :defaultSize="'1920x1080'" :uploadText="'Subir Capa'" :maxWidth="1920" />

      <label>
        Senha padrão dos alunos
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input v-model="state.senhaAlunos" type="text" spellCheck="false" autocomplete="false" @input="validarSenhaAlunos()" placeholder="Digite a senha aqui..." @keydown.enter="criarArea" />
      </div>

      <label>
        E-mail de suporte da área
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="envelope" />
        <input v-model="state.emailSuporte" type="email" spellCheck="false" autocomplete="false" placeholder="email@email.com" @keydown.enter="criarArea" />
      </div>

      <label>
        Cor da marca
        <span>*</span>
      </label>
      <div class="cor">
        <ColorPicker :valor="state.corMarca" :onAtualizarCor="atualizarCorSelecionada" />
      </div>

      <label>
        Formato da área
        <span>*</span>
      </label>
      <div class="formatos">
        <button class="ilhas" @click="selecionarFormato('ilhas')" :class="{ ativo: state.formatoArea === 'ilhas' }">
          <div class="icone">
            <Svgs nome="formato-ilha" />
          </div>
          <div class="texto">
            <h3>Ilhas</h3>
            <p>Formato em ilhas com os conteúdos em ordem</p>
          </div>
        </button>
        <button class="listas" @click="selecionarFormato('listas')" :class="{ ativo: state.formatoArea === 'listas' }">
          <div class="icone">
            <Svgs nome="formato-lista" />
          </div>
          <div class="texto">
            <h3>Listas</h3>
            <p>Formato mais prático com os conteúdos em listas</p>
          </div>
        </button>
        <button class="quadros" @click="selecionarFormato('quadros')" :class="{ ativo: state.formatoArea === 'quadros' }">
          <div class="icone">
            <Svgs nome="formato-quadro" />
          </div>
          <div class="texto">
            <h3>Quadros</h3>
            <p>Formato mais visual com os conteúdos em quadros</p>
          </div>
        </button>
      </div>

      <label>
        Tema da área
        <span>*</span>
      </label>
      <div class="temas">
        <button class="claro" @click="selecionarTema('claro')" :class="{ ativo: state.temaArea === 'claro' }">
          <div class="icone">
            <Svgs :nome="`formato-${state.formatoArea.slice(0, -1)}`" />
          </div>
          <div class="texto">
            <h3>Claro</h3>
            <p>Fundo branco com textos pretos</p>
          </div>
        </button>
        <button class="escuro" @click="selecionarTema('escuro')" :class="{ ativo: state.temaArea === 'escuro' }">
          <div class="icone">
            <Svgs :nome="`formato-${state.formatoArea.slice(0, -1)}`" />
          </div>
          <div class="texto">
            <h3>Escuro</h3>
            <p>Fundo preto com textos brancos</p>
          </div>
        </button>
      </div>

      <SalvarModal nome="Criar nova área" @click="criarArea" />
    </div>
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive } from 'vue'
import { useStoreAreas } from '@stores'
import { useStoreIlhasClaro, useStoreIlhasEscuro, useStoreListasClaro, useStoreListasEscuro, useStoreQuadrosClaro, useStoreQuadrosEscuro } from '@components/app/areas/templates'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import ColorPicker from '@components/global/elementos/ColorPicker.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeIlhasClaro = useStoreIlhasClaro()
const storeIlhasEscuro = useStoreIlhasEscuro()
const storeListasClaro = useStoreListasClaro()
const storeListasEscuro = useStoreListasEscuro()
const storeQuadrosClaro = useStoreQuadrosClaro()
const storeQuadrosEscuro = useStoreQuadrosEscuro()

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  emailSuporte: '',
  formatoArea: 'ilhas',
  temaArea: 'claro',
  senhaAlunos: '',
  corMarca: '#FFFFFF',
  personalizacao: {
    telaLogin: {},
    telaAlterarSenha: {},
    telaErro: {},
    telaLoading: {},
    telaCursosArea: {},
    telaCurso: {},
    telaModulo: {},
    telaAula: {},
    telaPerfil: {},
    menu: {}
  },
  imagemCapa: '',
  imagemLogo: ''
})

function atualizarImagemLogo(novaUrl) {
  state.imagemLogo = novaUrl
}

function atualizarImagemCapa(novaUrl) {
  state.imagemCapa = novaUrl
}

function atualizarCorSelecionada(novaCor) {
  state.corMarca = `#${novaCor}`
}

function selecionarFormato(formato) {
  state.formatoArea = formato
}

function selecionarTema(tema) {
  state.temaArea = tema
}

function configurarEstiloArea() {
  const storeMap = {
    IlhasClaro: storeIlhasClaro.personalizacao,
    IlhasEscuro: storeIlhasEscuro.personalizacao,
    ListasClaro: storeListasClaro.personalizacao,
    ListasEscuro: storeListasEscuro.personalizacao,
    QuadrosClaro: storeQuadrosClaro.personalizacao,
    QuadrosEscuro: storeQuadrosEscuro.personalizacao
  }

  const storeKey = `${capitalizeFirstLetter(state.formatoArea)}${capitalizeFirstLetter(state.temaArea)}`
  const store = storeMap[storeKey]
  console.log(store)
  const logotipo = state.imagemLogo
  const banner = state.imagemCapa
  const corMarca = state.corMarca
  const nome = state.nome

  if (store) {
    // Personalização base
    state.personalizacao.telaLogin = store.telaLogin
    state.personalizacao.telaAlterarSenha = store.telaAlterarSenha
    state.personalizacao.telaLoading = store.telaLoading
    state.personalizacao.telaCursosArea = store.telaInicio
    state.personalizacao.telaCurso = store.telaCurso
    state.personalizacao.telaModulo = store.telaModulo
    state.personalizacao.telaAula = store.telaAula
    state.personalizacao.telaPerfil = store.telaPerfil
    state.personalizacao.menu = store.menu
    // Personalização do Nome
    state.personalizacao.telaLogin.textoTitulo = nome
    state.personalizacao.menu.textoTitulo = nome
    state.personalizacao.telaCursosArea.textoCapaTitulo = nome
    // Personalizando o logotipo
    state.personalizacao.menu.imagemLogo = logotipo
    state.personalizacao.telaLogin.imagemLogo = logotipo
    state.personalizacao.telaAlterarSenha.imagemLogo = logotipo
    state.personalizacao.telaLoading.imagemLogo = logotipo
    // Personalização do Banner
    state.personalizacao.telaPerfil.imagemCapa = banner
    state.personalizacao.telaLogin.imagemFundo = banner
    state.personalizacao.telaAlterarSenha.imagemFundo = banner
    state.personalizacao.telaCursosArea.imagemCapaDesktop = banner
    state.personalizacao.telaCursosArea.imagemCapaMobile = banner
    // Personalizanco cor da marca
    state.personalizacao.telaLogin.corFundoBotao = corMarca
    state.personalizacao.telaAlterarSenha.corFundoBotao = corMarca
    state.personalizacao.telaLoading.corLoading = corMarca
    state.personalizacao.telaCursosArea.corFundoBotao = corMarca
    state.personalizacao.telaCursosArea.corProgressoAtual = corMarca
    state.personalizacao.menu.corIcones = corMarca
    state.personalizacao.telaCurso.corFundoBotao = corMarca
    state.personalizacao.telaCurso.corCaminho = corMarca
    state.personalizacao.telaModulo.corFundoBotao = corMarca
    state.personalizacao.telaModulo.corCaminho = corMarca
    state.personalizacao.telaAula.corLinks = corMarca
    state.personalizacao.telaAula.corArquivos = corMarca
    state.personalizacao.telaAula.corFundoBotaoComentar = corMarca
    state.personalizacao.telaAula.corFundoBotaoAula = corMarca
    state.personalizacao.telaPerfil.corTitulos = corMarca
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function validarHotlink() {
  let hotlink = state.hotlink

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlink = hotlink
}

function validarSenhaAlunos() {
  let senha = state.senhaAlunos

  senha = senha.replace(/\s+/g, '-')

  senha = senha.replace(/[^a-zA-Z0-9-_.~!*'();:@&=+$,/?%#\[\]]/g, '')

  state.senhaAlunos = senha
}

async function criarArea() {
  configurarEstiloArea()
  if (!validarDados()) return

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink?.trim(),
    imagemCapa: state.imagemCapa,
    imagemLogo: state.imagemLogo,
    senhaPadraoAlunos: state.senhaAlunos,
    emailSuporte: state.emailSuporte,
    personalizacao: {
      telaLogin: state.personalizacao?.telaLogin,
      telaAlterarSenha: state.personalizacao?.telaAlterarSenha,
      telaLoading: state.personalizacao?.telaLoading,
      telaCursosArea: state.personalizacao?.telaCursosArea,
      telaCurso: state.personalizacao?.telaCurso,
      telaModulo: state.personalizacao?.telaModulo,
      telaAula: state.personalizacao?.telaAula,
      telaPerfil: state.personalizacao?.telaPerfil,
      menu: state.personalizacao?.menu
    }
  }

  const adicionadoOk = await storeAreas.adicionar(payload)
  if (adicionadoOk !== true) return

  setTimeout(() => {
    window.open(`/areas/${payload.hotlink}`, '_self')
  }, 1000)
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.hotlink || !state.emailSuporte) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.emailSuporte = ''
  state.formatoArea = 'ilhas'
  state.temaArea = 'claro'
  state.senhaAlunos = ''
  state.corMarca = '#FFFFFF'
  state.personalizacao = {
    telaLogin: {},
    telaAlterarSenha: {},
    telaErro: {},
    telaLoading: {},
    telaCursosArea: {},
    telaCurso: {},
    telaModulo: {},
    telaAula: {},
    telaPerfil: {},
    menu: {}
  }
  state.imagemCapa = ''
  state.imagemLogo = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

.cor {
  width: 100%;
  position: relative;
}

.formatos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.formatos button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 5px);
  border-radius: 12px;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  transition: all 0.3s;
}

.formatos button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  min-height: 110px;
  border-radius: 10px 10px 0 0;
  background-color: var(--cor-cinza-4);
  transition: all 0.3s;
}

.formatos button .icone svg {
  width: 60px;
  min-width: 60px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.formatos button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
}

.formatos button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.formatos button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 10px 0;
}

.formatos button.ativo {
  border: 1px solid var(--cor-laranja);
}

.formatos button.ativo .icone {
  background-color: var(--cor-laranja);
}

.formatos button.ativo .icone svg {
  fill: var(--cor-branco-fixo);
}

.temas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.temas button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 5px);
  border-radius: 12px;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  transition: all 0.3s;
}

.temas button.ativo {
  border: 1px solid var(--cor-laranja);
}

.temas button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
  min-height: 110px;
  border-radius: 10px 10px 0 0;
  transition: all 0.3s;
}

.temas button.claro .icone {
  background-color: var(--cor-branco-fixo);
}

.temas button.claro .icone svg {
  fill: var(--cor-preto-fixo);
}

.temas button.escuro .icone {
  background-color: var(--cor-preto-fixo);
}

.temas button.escuro .icone svg {
  fill: var(--cor-branco-fixo);
}

.temas button.ativo .icone svg {
  fill: var(--cor-laranja);
}

.temas button .icone svg {
  width: 60px;
  min-width: 60px;
  transition: all 0.3s;
}

.temas button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
}

.temas button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.temas button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

label {
  margin: 20px 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .titulo .nome h3 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
  }

  .formatos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: scroll;
  }

  .formatos::-webkit-scrollbar {
    display: none;
  }

  .formatos button {
    width: 160px;
    min-width: 160px;
    margin: 0 10px 0 0;
  }

  .formatos button .texto p {
    width: 100%;
    max-width: 130px;
  }

  .temas button .texto p {
    width: 100%;
    max-width: 120px;
  }
}
</style>
