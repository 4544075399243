<template>
  <div id="personalizar">
    <ModalExcluirArea />
    <ModalEditarArea />
    <ModalFavicon />

    <ModalIntegrarFacebook />
    <ModalIntegrarGoogleTag />

    <SectionEdicao />
    <SectionAjustes />
    <SectionTela />
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import SectionAjustes from './partials/SectionAjustes.vue'
import SectionEdicao from './partials/SectionEdicao.vue'
import SectionTela from './partials/SectionTela.vue'

import ModalIntegrarGoogleTag from './modals/ModalIntegrarGoogleTag.vue'
import ModalIntegrarFacebook from './modals/ModalIntegrarFacebook.vue'

import ModalFavicon from './modals/ModalFavicon.vue'
import ModalExcluirArea from '@components/app/areas/modals/ExcluirArea.vue'
import ModalEditarArea from '@components/app/areas/modals/EditarArea.vue'

const route = useRoute()
const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()

async function carregarArea(hotlink) {
  await storeAreas.receberPorHotlink(hotlink)
  storePersonalizacao.personalizacao = storeAreas.area?.personalizacao
}

watch(
  () => route.params.hotlinkArea || route.query.hotlinkArea,
  async (hotlink) => {
    if (hotlink) {
      await carregarArea(hotlink)
    }
  },
  { immediate: true }
)
</script>

<style>
body {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none !important;
}

body * {
  scrollbar-width: none;
}

body *::-webkit-scrollbar {
  display: none !important;
}
</style>
