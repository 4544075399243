<template>
  <Modal nome="criarModulo">
    <label class="um">
      Nome do módulo
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Módulo Marketing" @keydown.enter="criarModulo" />

    <label>
      Descrição do módulo
      <span>*</span>
    </label>
    <input v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Módulo com foco em marketing" @keydown.enter="criarModulo" />

    <label>
      Imagem de capa do módulo
      <span>*</span>
    </label>
    <UploadArquivo :valor="state.imagemCapa" :onAtualizarImagem="atualizarImagemCapa" :defaultSize="'1280x720'" :uploadText="'Subir Capa'" :maxWidth="1280" />

    <label>
      Curso do módulo
      <span>*</span>
    </label>
    <div class="select">
      <Svgs nome="setinha" />
      <select v-model="state.idCurso">
        <option v-for="(curso, index) in storeCursos.cursos" :key="index" :value="curso._id">Curso {{ curso.nome }}</option>
      </select>
    </div>

    <SalvarModal nome="Criar Novo Módulo" @click="criarModulo()" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, computed, watch } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModal, useStoreModulos } from '@stores'
import { helperTexto } from '@helpers'

import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const storeModulos = useStoreModulos()

const dadosModal = computed(() => storeModal.dadosDoModal('criarModulo'))

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  imagemCapa: '',
  idCurso: ''
})

function atualizarImagemCapa(novaUrl) {
  state.imagemCapa = novaUrl
}

function nomeParaHotlink() {
  state.hotlink = helperTexto.converterEmHotlink(state.nome)
}

async function criarModulo() {
  if (!validarDados()) return

  nomeParaHotlink()

  const idArea = storeAreas.area?._id
  const idCurso = state.idCurso

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink,
    imagemCapa: state.imagemCapa
  }

  const adicionadoOk = await storeModulos.adicionar(idArea, idCurso, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarModulos')
  storeModal.fecharModal('criarModulo')
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.imagemCapa) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.imagemCapa = ''
}

async function pegarIdCurso() {
  if (dadosModal.value.id) {
    state.idCurso = dadosModal.value.id
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdCurso()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvar {
  margin: 20px 0 0 0;
}
</style>
